import {useState} from 'react';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Powerbi from './components/Powerbi';
import './App.css';

function App() {
  const [reportRendered, setReportRendered] = useState(false);

  return (
    <div className="App">
      <Header />
      <Navbar reportRendered={reportRendered} />
      <Powerbi setReportRendered={setReportRendered}/>
    </div>
  );
}

export default App;
