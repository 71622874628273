import {useEffect, useState} from 'react';
import './Navbar.css';

const Navbar = (props) => {
  const {reportRendered} = props;
  const [page, setPage] = useState([]) //window.report.getPages();

  useEffect(() => {
    if(reportRendered){
      window.report.getPages().then((data) => {
        console.log(data);
        setPage(data)
      });
    }
  }, [reportRendered]);

  const navPage = (data) => {
    console.log(data);
    window.report.setPage(data.name, data.displayName)
  }

  return (
    <ul className="side-nav">
      {
        page.map((data) => {
          return (
            <li onClick={() => {navPage(data)}}>{data.displayName}</li>
          )
        })
      }
    </ul>
  )
};

export default Navbar;