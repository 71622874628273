import {useState, useEffect} from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './Powerbi.css';

const Powerbi = (props) => {
	const {setReportRendered} = props;
	const [reportData, setReportData] = useState(null);

	useEffect(() => {
		async function fetchAPI() {
      let response = await fetch('/getEmbedToken')
      response = await response.json()
      console.log(response);
			setReportData(response);
    }

    fetchAPI()
	}, [])
	return (
		<div>
			<PowerBIEmbed
				embedConfig = {{
					type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
					// id: 'f774bfb1-a114-4b23-9faa-7d0277a5ff0c',
					embedUrl: reportData?.embedUrl[0].embedUrl,
					accessToken: reportData?.accessToken,
					tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
					settings: {
						panes: {
							filters: {
								expanded: false,
								visible: false
							}
						},
					}
				}}

				eventHandlers = {
					new Map([
						['rendered', function () {
							setReportRendered(true);
							console.log('Report rendered');
						}]
					])
				}

				cssClassName = { "powerbi-report" }

				getEmbeddedComponent = { (embeddedReport) => {
					window.report = embeddedReport;
				}}
			/>
		</div>
	)
};

export default Powerbi;